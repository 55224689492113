import React, { useState } from 'react'
import { Accordion } from '@truphone/accordion'

export default function FAQs(props) {
  const [expanded, setExpanded] = useState(`faq-panel0`)

  const handleChange = (panel) => {
    setExpanded(panel !== expanded ? panel : false)
  }
  return (
    <div className={`${props.sideComponent ? '' : 'py-10 lg:py-20'}`}>
      <div className={`container max-w-5xl mx-auto ${props.sideComponent ? '' : 'px-4'}`}>
        {props.title && <h2 className="text-3xl font-light mb-10 text-center">{props.title}</h2>}
        {props.faqs &&
          props.faqs.map((faq, i) => {
            return (
              <div key={`faq-panel${i}`} className="my-3 px-4 border-2 border-gray-500 rounded-sm">
                <Accordion
                  {...faq}
                  faqsList
                  title={faq.title}
                  expanded={expanded === `faq-panel${i}`}
                  onChange={() => handleChange(`faq-panel${i}`)}
                  sideComponent={props.sideComponent}
                >
                  <div
                    className="text-base text-left p-margin link-blue list-disc"
                    dangerouslySetInnerHTML={{ __html: faq.content }}
                  />
                </Accordion>
              </div>
            )
          })}
      </div>
    </div>
  )
}
