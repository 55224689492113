import React from 'react'
import FAQs from './FAQs'
import { uppercaseString } from '@truphone/utils'

export default function FAQsBlock(props) {
  return (
    <div className="container mx-auto px-6">
      <div className="flex flex-wrap -m-4 my-10 lg:mt-32">
        <div className="w-full lg:w-1/2 px-4 py-2 lg:p-4">
          {props.topTitle && (
            <p className={`overline-text mb-4 text-grey-700`}>{uppercaseString(props.topTitle)}</p>
          )}
          {props.title && (
            <h2 className="h3-light tracking-normal leading-tight mb-6">{props.title}</h2>
          )}
          {props.subtitle && <h3 className="text-xl font-semibold mb-6">{props.subtitle}</h3>}
          {props.content && (
            <div className="text-grey-700" dangerouslySetInnerHTML={{ __html: props.content }} />
          )}
        </div>
        <div className="w-full lg:w-1/2 px-4 py-2 lg:p-4">
          {props.faqs && <FAQs sideComponent faqs={props.faqs} />}
        </div>
      </div>
    </div>
  )
}
